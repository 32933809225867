<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-actions"></div>
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item label="班组">
              <a-select
                class="form-control lg test"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :filterOption="filterByPY"
                :options="leaderList"
              ></a-select>
            </a-form-item>
            <a-form-item label="工人">
              <a-input class="form-control" v-decorator="['workerName']" placeholder="请输入工人姓名"></a-input>
            </a-form-item>
            <a-form-item label="状态">
              <a-select
                class="form-control"
                v-decorator="['status']"
                placeholder="请选择审核状态"
                allowClear
                :options="typesMap.status"
              ></a-select>
            </a-form-item>
            <a-form-item label="考勤月份">
              <a-select
                class="form-control mutiple-select"
                :options="monthOptions"
                v-model="months"
                mode="multiple"
                placeholder="选择考勤月份"
              ></a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form">
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">申请人：</div>
            <div class="info-col-content">{{ modal.data.workerName }}</div>
          </div>
          <div class="info-col">
            <div class="info-col-label">电话：</div>
            <div class="info-col-content">{{ modal.data.workerPhoneNumber }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">身份证号：</div>
            <div class="info-col-content">{{ modal.data.workerCardNumber }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">班组：</div>
            <div class="info-col-content">{{ modal.data.leaderName }}</div>
          </div>
          <div class="info-col">
            <div class="info-col-label">班组电话：</div>
            <div class="info-col-content">{{ modal.data.leaderPhoneNumber }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">项目：</div>
            <div class="info-col-content">{{ modal.data.projectName }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">考勤记录：</div>
            <div class="info-col-content">
              <table class="attendance-table" v-if="modal.data.inOutVOList">
                <tr class="bg-gray-300">
                  <td>进场时间</td>
                  <td>出场时间</td>
                </tr>
                <tr v-for="(item, index) in modal.data.inOutVOList" :key="index">
                  <td>{{ item.inn }}</td>
                  <td>{{ item.out }}</td>
                </tr>
              </table>
              <span v-else>无</span>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="flex-1 info-col">
            <div class="info-col-label">异常考勤解释：</div>
            <div class="flex-1 info-col-content">
              <a-form-item>
                <a-textarea
                  :disabled="modal.type === 'DETAIL'"
                  v-decorator="[
                    'abnormalReason',
                    {
                      rules: [{ required: true, message: '请输入' }],
                    },
                  ]"
                  placeholder="请输入异常考勤解释"
                ></a-textarea>
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="leading-10 info-row">
          <div class="info-col">
            <div class="info-col-label">进场：</div>
            <div class="info-col-content">
              <a-form-item>
                <a-time-picker
                  :disabled="modal.type === 'DETAIL'"
                  format="HH:mm:ss"
                  v-decorator="[
                    'inn',
                    {
                      rules: [{ required: true, message: '请选择进场时间' }],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="info-col">
            <div class="info-col-label">出场：</div>
            <div class="info-col-content">
              <a-form-item>
                <a-time-picker
                  :disabled="modal.type === 'DETAIL'"
                  v-decorator="[
                    'out',
                    {
                      rules: [{ required: true, message: '请选择出场时间' }],
                    },
                  ]"
                  format="HH:mm:ss"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">上传凭证：</div>
            <div class="flex-1 info-col-content">
              <a-form-item>
                <PictureCardListUpload
                  :disabled="modal.type === 'DETAIL'"
                  v-decorator="[
                    'certificationList',
                    {
                      rules: [{ required: true, message: '请上传凭证' }],
                    },
                  ]"
                  ref="pictureCardListUpload"
                ></PictureCardListUpload>
              </a-form-item>
              <div class="mt-2 text-xs text-yellow-500">
                请上传监控或其他具有效力的证明图片（PNG、JPG、gif）作为凭证，总大小不超过10M
              </div>
            </div>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
import moment from 'moment'
import PictureCardListUpload from '@/components/PictureCardListUpload'
export default {
  components: { PictureCardListUpload },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '工友',
        width: '12%',
        align: 'center',
        dataIndex: 'workerName',
      },
      {
        title: '班组',
        width: '12%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '项目',
        width: '12%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '异常考勤日',
        width: '12%',
        align: 'center',
        dataIndex: 'abnormalDate',
      },
      {
        title: '状态',
        width: '12%',
        align: 'center',
        dataIndex: 'abnormalStatus',
        customRender: (text, row, index) => {
          return <span>{this.translateType(text, 'status')}</span>
        },
      },
      {
        title: '审核人',
        width: '12%',
        align: 'center',
        dataIndex: 'operator',
      },
      {
        title: '审核时间',
        width: '12%',
        align: 'center',
        dataIndex: 'operatorTime',
      },
      {
        title: '操作',
        width: '20%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                {row.abnormalStatus === 0 && (
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.audit(row)
                    }}
                  >
                    审核
                  </a>
                )}
                {row.abnormalStatus === 1 && (
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.showDetail(row)
                    }}
                  >
                    查看
                  </a>
                )}
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        type: 'AUDIT', // AUDIT || DETAIL
        data: {},
      },

      projectList: [],
      leaderList: [],
      workerList: [],
      months: [moment().format('YYYY-MM')],
    }
  },
  computed: {
    typesMap() {
      return {
        status: [
          { value: 0, label: '未审核' },
          { value: 1, label: '已通过' },
        ],
      }
    },
    attendenceWorkerIds() {
      return this.selectedRows.map((row) => {
        return row.workerId
      })
    },
    monthOptions() {
      const result = []
      for (let i = 0; i < 6; i++) {
        const month = moment().subtract(i, 'month').format('YYYY-MM')
        result.push({
          label: month,
          value: month,
        })
      }
      return result
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/web/abnormal/list', {
          ...this.getPageParams(_pageNo, _pageSize),
          dateList: this.months,
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async showDetail(row) {
      this.resetModal()
      const { data: detail } = await this.$axios.post(`/attendance/web/abnormal/detail/${row.id}`)
      this.modal.visible = true
      this.modal.type = 'DETAIL'
      this.modal.title = '工人异常考勤查看'
      this.modal.data = { ...detail }
      const { inn, out, abnormalReason, certificationList } = detail
      this.safeSetFieldsValue(this.modal.form, {
        inn: moment(inn || '09:00:00', 'HH:mm:ss'),
        out: moment(out || '17:00:00', 'HH:mm:ss'),
        abnormalReason,
        certificationList,
      })
    },
    async audit(row) {
      this.resetModal()
      const { data: detail } = await this.$axios.post(`/attendance/web/abnormal/detail/${row.id}`)
      this.modal.visible = true
      this.modal.type = 'AUDIT'
      this.modal.title = '工人异常考勤审核'
      this.modal.data = { ...detail }
      const { inn, out, abnormalReason, certificationList } = detail
      this.safeSetFieldsValue(this.modal.form, {
        inn: moment(inn || '09:00:00', 'HH:mm:ss'),
        out: moment(out || '17:00:00', 'HH:mm:ss'),
        abnormalReason,
        certificationList,
      })
    },

    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const actionUrl = '/attendance/web/abnormalAudit'
            const { bizNo } = this.modal.data
            await this.$axios.post(actionUrl, {
              bizNo,
              ...params,
              inn: params.inn.format('HH:mm:ss'),
              out: params.out.format('HH:mm:ss'),
            })
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.resetTable()
      this.leaderList = await getLeaderList(projectCode)
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
    const defaultProject = this.projectList.length ? this.projectList[0].value : ''
    if (defaultProject) {
      this.form.setFieldsValue({ projectCode: defaultProject })
      this.leaderList = await getLeaderList(defaultProject)
    }
  },
}
</script>

<style lang="less" scoped>
.info-row {
  display: flex;
  margin-bottom: 15px;
  .info-col {
    display: flex;
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
      .info-col-label {
        width: 100px;
        text-align: right;
      }
    }
    .info-col-content {
      margin-left: 5px;
    }
  }
}
.attendance-table {
  td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 2px 8px;
  }
}
</style>
